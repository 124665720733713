import manageHalls from "./pages/setting-halls/store"
import manageOffice from "./pages/srtting-office/store"
import aboutManaraApp from "./pages/about-manara-app/store"
import manageBeneficiary from "./pages/manage-beneficiary/store"
import manageUsers from "./pages/manage-user-center/store"
import manageRols from "./pages/role/store"
import selects from "./pages/selects/store"
export default {
  namespaced: true,
  modules:{
    manageHalls,
    manageOffice,
    aboutManaraApp,
    manageBeneficiary,
    manageUsers,
    manageRols,
    selects
  }
}
