<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  methods: {

    fetchToken() {
      this.$axios.get('/opportunities').then((res) => {
        console.log("res res res")
      }).catch(() => {
        console.log("tetete")
      })
    }

  },
  beforeUnmount() {
    // this.fetchToken()
    console.log("accessToken", localStorage.getItem('accessToken'))
  },
  // created(){
  //   this.fetchToken()
  // },
  beforeMount() {
    // console.log("accessToken", localStorage.getItem('accessToken'))
    // this.$axios.get('/opportunities').then((res) => {
    //   this.$router.push({ name: "auth-login" })
    // }).catch(() => {
    //   console.log("tetete")
    // })
  },

  beforeCreate() {

    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'rtl')

  },

  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
<style lang="scss">
// @font-face {
//   font-family: "Noto Kufi Arabic";
//   src: local("Noto Kufi Arabic"), local("NotoKufiArabic"),
//     url("./assets/fonts/NotoKufiArabic-Regular.ttf") format("ttf");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Noto Kufi Arabic";
//   src: local("Noto Kufi Arabic Bold"), local("NotoKufiArabic-Bold"),
//     url("./assets/fonts/NotoKufiArabic-Bold.ttf") format("ttf");
//   font-weight: bold;
//   font-style: normal;
// }
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@100;200;300;400;500;600;700;800;900&display=swap");

body :dir(rtl) {
  -webkit-font-family: "Cairo", sans-serif !important;
  -o-font-family: "Cairo", sans-serif !important;
  font-family: "Cairo", sans-serif !important;
}

html[dir="rtl"] svg.feather {
  transform: none !important;
}
</style>
