import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    categories: [],
    loading: false,
  },

  getters: {
    categories(state) {
      return state.categories;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations: {},
  actions: {

    async update(context,payload){
      context.state.loading = true;
      await api.post(payload.ep,payload.data).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });

      })
    },

    async delete(context,payload){
      context.state.loading = true;
      await api.delete(payload.ep).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      })
    },

    async create(context,payload){
      context.state.loading = true;
      await api.post(payload.ep ,payload.data).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon:  res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });

      })
    },
  
  },
};
