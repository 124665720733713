import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters:{
    loading(state){
      return state.loading;
    }
  },
  mutations:{},
  actions:{
    async completed(context,payload){
      context.state.loading=true;
      api.get( payload.ep ,{params:{status:payload.status}}).then(res=>{
        context.state.loading=false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon" ,
            variant: res.data.status ? "success" :"danger",
          },
        });
      })
    }
  }
}
