import axios from "@axios";
// import { colors, Notify } from 'quasar'
import * as ep from "./endpoints";

class AuthService {
  async login(payload) {
    return await axios
      .post(ep.LOGIN, payload)
      .then((res) => res.data)
      .catch((err) => Promise.reject(err));
  }

  async logout() {
    return await axios.post(ep.LOGOUT).then((res) => res.data);
  }

}

const authService = new AuthService();
export default authService;
