export default [
  {
    path: "/auth",
    component: () => import("./moduleLayout.vue"),
    children: [
      {
        path: "login",
        name: "auth-login",
        component: () => import("./pages/login/index.vue"),
        meta: {
          layout: "full",
          resource: "Auth",
          redirectIfLoggedIn: true,
        },
      },
    ],
  },
];
