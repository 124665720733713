import authHelpers from "@/utils/auth-helpers";
export default {
  namespaced: true,
  state: {
    loading: false,
    user: authHelpers.getUser(),
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      authHelpers.setUser(user);
    },
  },
  getters: {
    user(state) {
      return state.user || authHelpers.getUser();
    },
  },
};
