import activites from "./bookings-page/events-activity/store";
import halls from "./bookings-page/halls/store";
import procedure from "./bookings-page/procedure/store";
export default {
  namespaced: true,
  modules: {
    activites,
    halls,
    procedure,
  },
};
