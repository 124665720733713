import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        loading: false,
        parmassins: {},
        loadingParmassins: false,
        Createloading: false,
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        parmassins(state) {
            return state.parmassins;
        },
        loadingParmassins(state) {
            return state.loadingParmassins;
        },
        Createloading(state) {
            return state.Createloading;
        },
    },
    mutations: {
        setParmassins(state, payloade) {
            state.parmassins = payloade;
        },
    },
    actions: {
        async getParmassins(context, payloade) {
            context.state.loadingParmassins = false;
            await api.get(ep.ROLE_BY_ID(payloade.id)).then((res) => {
                context.commit("setParmassins", res.data.data);
                context.state.loadingParmassins = true;
            });
        },
        async createRole(context, paylode) {
            context.state.Createloading = true;
            await api.post(ep.ROLE, paylode).then((res) => {
                context.state.Createloading = false;
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title: res.data.message,
                        icon:  res.data.status ? "CheckIcon": "XIcon",
                        variant: res.data.status ? "success" :"danger",
                    },
                });
            }).catch((err) => {
                console.log(err);
            })
                .finally(() => {
                    context.state.loading = false;
                });
        },
        async updateRole(context, paylode) {
            context.state.Createloading = true;
            await api.put(ep.ROLE_BY_ID(paylode.id), paylode.data).then((res) => {
                context.state.Createloading = false;
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title: res.data.message,
                        icon: res.data.status ? "CheckIcon": "XIcon",
                        variant: res.data.status ? "success" :"danger",
                    },
                });
            }).catch((err) => {
                console.log(err);
            })
                .finally(() => {
                    context.state.loading = false;
                });
        },

        async deleteRolr(context, payloade) {
            context.state.Createloading = true;
            await api.delete(ep.ROLE_BY_ID(payloade.id)).then((res) => {
                context.state.Createloading = false;
                Vue.$toast({
                    component: ToastificationContent,
                    props: {
                        title: res.data.message,
                        icon: res.data.status ? "CheckIcon": "XIcon",
                        variant: res.data.status ? "success" :"danger",
                    },
                });
            }).catch((err) => {
                console.log(err);
            })
                .finally(() => {
                    context.state.loading = false;
                });
        },

    },
};
