import AuthService from "./services";
import AuthHelpers from "@/utils/auth-helpers";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";

// import router from "@/router/index";
// import * as ep from "./endpoints";
// import api from "@/libs/axios";
// import Vue from "vue";

export default {
  namespaced: true,
  state:{
    loading:false,
    accessToken: AuthHelpers.getAccessToken(),
    tokenType: AuthHelpers.getAccessTokenType(),

  },
  getters:{
    accessToken(state) {
      return state.accessToken;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations:{
    setLoading(state, value) {
      state.loading = value;
    },
    setToken(state, value) {
      state.accessToken = value;
      axios.defaults.headers["Authorization"] = `Bearer ${value}`;
      AuthHelpers.setAccessToken(value);
    },
    setTokenType(state, value) {
      state.tokenType = value;
      AuthHelpers.setAccessTokenType(value);
    },
  },
  actions:{
    async login({ commit, state }, payload) {
      commit("setLoading", true);
      try {
        const data = await AuthService.login(payload);
        if (data.status === false) {
          throw data.message;
        }
        AuthHelpers.reset();
        commit("setToken", data.data.token);
        commit("User/setUser", data.data.user, { root: true });
        // router.push({name:"dashboard-home"})
      } catch (err) {
        console.log(err);
        this._vm.$toast({
          component: ToastificationContent,
          props: {
            title: "خطأ في البيانات",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        commit("setLoading", false);
        return Promise.reject(false);
      } finally {
        commit("setLoading", false);
      }
    },
    getAccessToken() {
      const accessToken = AuthHelpers.getAccessToken();
      if (accessToken) {
        return accessToken;
      } else {
        return Promise.reject("unauthenticated");
      }
    },
    async logout({ commit }) {
      commit("setLoading", true);
      try {
        await AuthService.logout();
        AuthHelpers.reset();
      } catch (err) {
        console.error(err);
      } finally {
        commit("setLoading", false);
      }
    },
  }
}
