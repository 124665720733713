import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    services:[],
    userOfficeId:null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    services(state){
      return state.services;
    },
    userOfficeId(state){
      return state.userOfficeId
    }
  },
  mutations: {},
  actions: {
    async updateOffices(context, payload) {
      context.state.loading = true;
      await api.put(ep.OFFICES_BY_ID(payload.id), payload.data).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon:  res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },

    async getServices(context, payload) {
      context.state.loading = true;
      await api.get(ep.SERVICES).then((res) => {
        context.state.loading = false;
        context.state.services=res.data.data
      }).catch((err) => {
        console.log(err);
      })
      .finally(() => {
        context.state.loading = false;
      });;
    },
    async updateServices(context, payload) {
      context.state.loading = true;
      await api.post(ep.SERVICES_BY_ID(payload.id), payload.data).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      }).catch((err) => {
        console.log(err);
      })
      .finally(() => {
        context.state.loading = false;
      });;
    },
    async updateUserOffice(context, payload) {
      context.state.loading = true;
      await api.post(ep.USEROFFICE_BY_ID(payload.id), payload.data).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      }).catch((err) => {
        console.log(err);
      })
      .finally(() => {
        context.state.loading = false;
      });;
    },

    async getUserOffice(){
      context.state.loading = true;
      await api.get(ep.USEROFFICE).then((res) => {
        context.state.loading = false;
        context.state.userOfficeId=res.data.data
      }).catch((err) => {
        console.log(err);
      })
      .finally(() => {
        context.state.loading = false;
      });;
    },

    async createOffices(context, payload) {
      context.state.loading = true;
      await api
        .post(ep.OFFICES, payload.data)
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async deleteOffices(context, payload) {
      context.state.loading = true;
      await api.delete(ep.OFFICES_BY_ID(payload.id)).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon:  res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },
  },
};
