import * as ep from "../../../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading(state){
      return state.loading
    }
  },
  mutations: {},
  actions: {
    async createBookingProcedures(context, payload) {
      context.state.loading = true;
      await api
        .post(ep.PROCEDURE, payload.data)
        .then((res) => {
          context.state.loading = false;

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },


    async updateBookingProcedures(context, payload) {
      context.state.loading = true;
      await api
        .put(ep.PROCEDURE_BY_ID(payload.id), payload.data)
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },

    async deleteBookingProcedures(context, payload) {
      context.state.loading = true;
      await api.delete(ep.PROCEDURE_BY_ID(payload.id)).then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },


    
    
    async confirm(context, payload) {
      context.state.loading = true;
      await api
        .get(ep.CONFIRM(payload.id), { params: { status: payload.status } })
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        }).catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
  },
};
