import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
  },
  mutations: {},

  actions: {
    
    async updateVisitor(context, payload) {
      context.state.loading = true;
      await api
        .put(ep.VISITORS_BY_ID(payload.id), payload.fd)
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" : "danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async createVisitor(context, payload) {
      context.state.loading = true;
      await api
        .post(ep.VISITORS, payload.fd)
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" : "danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async deleteVisitor(context, payload) {
      context.state.loading = true;
      await api.delete(ep.VISITORS_BY_ID(payload.id)).then((res) => {
        context.dispatch("getJobs", {
          page: payload.page,
          per_page: payload.per_page,
          filter: payload.filter,
          type_id: payload.type_id,
        });
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon:  res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" : "danger",
          },
        });
      });
    },
  },
};
