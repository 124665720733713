import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    lecturers:{}
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    lecturers(state){
      return state.lecturers;
    }
  },
  mutations: {},
  actions: {
    async getLecturersById(context, payload){
      context.state.loading = true;
      await api.get(ep.LECTURERS_BY_ID(payload.id)).then((res) => {
        context.state.loading = false;
        context.state.lecturers=res.data.data
      });
    },
    async updateLecturers(context, payload) {
      context.state.loading = true;
      await api.post(ep.LECTURERS_BY_ID(payload.id), payload.fd).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },
    async createLecturers(context, payload) {
      context.state.loading = true;
      await api
        .post(ep.LECTURERS, payload.fd)
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async deleteLecturers(context, payload) {
      context.state.loading = true;
      await api.delete(ep.LECTURERS_BY_ID(payload.id)).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },
  },
};
