import evaluation from "./pages/evaluation/store"
import screenTV from "./pages/tv/store"
import Queue from "./pages/services/store"
export default {
  namespaced: true,
  modules:{
    evaluation,
    screenTV,
    Queue
  }
}
