import event from "./pages/events/store"
import activity from "./pages/activite/store"
import jobs from "./pages/jobs/store"
import procedures from "./pages/procedure/store"
import lecturers from "./pages/lecturers/store"
import interactiveMap from "./pages/interactive-map/store"
import archives from "./pages/archives/store"
import notifictions from "./pages/notifictions/store"
import home from "./pages/home/store"
export default {
  namespaced: true,
  modules:{
    event,
    activity,
    jobs,
    procedures,
    lecturers,
    interactiveMap,
    archives,
    notifictions,
    home
  }
}
