import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import manageApp from "../modules/manage-app/store"
import Auth from "../modules/auth/store"
import User from "./user";
import manageCenter from '../modules/manage-center/store'
import settings from "../modules/settings/store"
import publicPages from "../modules/public-pages/store"
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    User,
    app,
    appConfig,
    verticalMenu,
    manageApp,
    Auth,
    manageCenter,
    settings,
    publicPages,
   
  },
  strict: process.env.DEV,
})
