import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
export default {
  namespaced: true,
  state: {
    loading: false,
    loadingUpdate:false,
    loadingCreate:false,
    hallsSirvice:[],
    hallDetails:{},
    appointime:[],
  },
  getters: {
    loading(state){
      return state.loading
    },
    hallsSirvice(state){
      return state.hallsSirvice
    },
    hallDetails(state){
      return state.hallDetails
    },
    appointime(state){
      return state.appointime
    },
    loadingUpdate(state){
      return state.loadingUpdate;
    },
    loadingCreate(state){
      return state.loadingCreate
    }
  },
  mutations: {},
  actions: {
    async updateHallsOccupancy(context, payload) {
      context.state.loading = true;
      await api.put(ep.HALLS_OCCUPANCIES_BY_ID(payload.id), payload.data).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },
    async createHallsOccupancy(context, payload) {
      context.state.loading = true;
      await api
        .post(ep.HALLS_OCCUPANCIES, payload.data)
        .then((res) => {
          context.state.loading = false;
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async deleteHallsOccupancy(context, payload) {
      context.state.loading = true;
      await api.delete(ep.HALLS_OCCUPANCIES_BY_ID(payload.id)).then((res) => {
        context.state.loading = false;
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      });
    },

    async getAppointments(context, payload){
      context.state.loading = true;
      await api
        .get(ep.APPOINTMENTS_OCCUPANCIES,{params:{hall_occupancy_id:payload.id}})
        .then((res) => {
          context.state.loading = false;
          context.state.appointime=res.data.data;
        }).catch((err) => {
          console.log(err);
          context.state.loading = false;
          context.state.appointime=[];
        }).finally(() => {
          context.state.loading = false;
        });
    },

    async createAppointments(context, payload){
      context.state.loading = true;
      await api
        .post(ep.APPOINTMENTS_OCCUPANCIES, payload.data)
        .then((res) => {
          context.state.loadingcontext, 
          context.dispatch("getAppointments",{id:payload.id})
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async updateAppointments(context, payload){
      context.state.loading = true;
      await api.put(ep.APPOINTMENTS_OCCUPANCIES_Id(payload.id), payload.data).then((res) => {
        context.state.loading = false;
        context.dispatch("getAppointments",{id:payload.data.hall_occupancy_id})
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      }).catch((err) => {
        console.log(err);
      })
      .finally(() => {
        context.state.loading = false;
      });
    },
    async deleteAppointments(context, payload){
      context.state.loading = true;
      await api.delete(ep.APPOINTMENTS_OCCUPANCIES_Id(payload.id),{params:{
        hall_occupancy_id:payload.appontime_id
      }}).then((res) => {
        context.state.loading = false;
        context.dispatch("getAppointments",{id:payload.appontime_id})

        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: res.data.status ? "CheckIcon": "XIcon",
            variant: res.data.status ? "success" :"danger",
          },
        });
      }).catch((err) => {
        console.log(err);
      })
      .finally(() => {
        context.state.loading = false;
      });
    }
  },
};
