import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading(state){
        return state.loading;
    }
  },
  mutations: {},
  actions: {
    async evaluationStatus(context, payload) {
      context.state.loading = true;
      await api
        .post(ep.SEND_NUMBER,{status:payload.status , phone_number:payload?.phone_number})
        .then((res) => {
          context.state.loading = false;
        })
        .catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
  },
};
