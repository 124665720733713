import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
// import { useQuery } from '@cytools/vue-query';
// import { useQuery, useQueryClient, useMutation } from '@cytools/vue-query';
export default {
  namespaced: true,
  state: {
    loading: {},
    hallsSirvice:[],
    hallDetails:{}
  },
  getters: {
    loading(state){
      return state.loading
    },
    hallsSirvice(state){
      return state.hallsSirvice
    },
    hallDetails(state){
      return state.hallDetails
    }

  },
  mutations: {},
  actions: {
    async getHallsSirvice(context, payload) {

      // const fetcher = async () =>
      // await fetch(`https://jsonplaceholder.typicode.com/posts/1`).then(
      //   (response) => response.json()
      // );
    // const { isLoading, isError, isFetching, data, error } = useQuery(
    //   "get-halls-sirvice",
    //   async  () =>await api.get("/halls-status")
    //     .then((res) => {
    //       context.state.loading = false;
    //       context.state.hallsSirvice=res.data.data;
    //     }),
    // );
    // context.state.loading = isLoading;
    // context.state.hallsSirvice=data;

      context.state.loading = true;
      await api
        .get("/halls-status")
        .then((res) => {
          context.state.loading = false;
          context.state.hallsSirvice=res.data.data;
        })
        .catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async getHallDetails(context, payload) {
      context.state.loading = true;
      await api
        .get("/hall-appointments/1")
        .then((res) => {
          context.state.loading = false;
          context.state.hallDetails=res.data.data;
        })
        .catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    
    
  },
};
