import * as ep from "../../endpoints";
import api from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    about: {},
    versions: {},
    loading: false,
  },
  getters: {
    about(state) {
      return state.about;
    },
    loading(state) {
      return state.loading;
    },
    versions(state){
        return state.versions;
    }
  },
  mutations: {},
  actions: {

async updateAboutContent(context,payload){
  context.state.loading = true;
  await api.put(ep.ABOUT_BY_ID(payload.id),payload.data).then((res) => {
    context.state.loading = false;
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: res.data.message,
        icon: res.data.status ? "CheckIcon": "XIcon",
        variant: res.data.status ? "success" :"danger",
      },
    });

  })
},

async deletAboutContent(context,payload){
  context.state.loading = true;
  await api.delete(ep.ABOUT_BY_ID(payload.id)).then((res) => {
    context.state.loading = false;
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: res.data.message,
        icon: res.data.status ? "CheckIcon": "XIcon",
        variant: res.data.status ? "success" :"danger",
      },
    });
  })
},

async createAboutContent(context,payload){
  context.state.loading = true;
  await api.post(ep.ABOUT ,payload.data).then((res) => {
    context.state.loading = false;
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: res.data.message,
        icon: res.data.status ? "CheckIcon": "XIcon",
        variant: res.data.status ? "success" :"danger",
      },
    });

  })
},


    async getAbout(context) {
      context.state.loading = true;
      await api.get(ep.ABOUT).then((res) => {
        context.state.about = res.data.data;
        context.state.loading = false;
      });
    },
    
    async getVersions(context) {
        context.state.loading = true;
        await api.get(ep.VERSIONS).then((res) => {
          context.state.versions = res.data.data[0];
          context.state.loading = false;
        });
    },

    async getUpdateAbout(context, payload) {
      context.state.loading = true;
      await api
        .put(ep.ABOUT_BY_ID(payload.id),{content:payload.content})
        .then((res) => {
          context.state.loading = false;
          context.dispatch("getAbout")
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: res.data.status ? "CheckIcon": "XIcon",
              variant: res.data.status ? "success" :"danger",
            },
          });
        })
        .catch((err) => {
          console.log(err);
          context.state.loading = false;
        })
        .finally(() => {
          context.state.loading = false;
        });
    },
    async updateVersions(context,payload) {
        context.state.loading = true;
        await api
          .put(ep.VERSIONS_BY_ID(payload.id), payload.data)
          .then((res) => {
            context.state.loading = false;
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon:  res.data.status ? "CheckIcon": "XIcon",
                variant: res.data.status ? "success" :"danger",
              },
            });
          })
          .catch((err) => {
            console.log(err);
            context.state.loading = false;
          })
          .finally(() => {
            context.state.loading = false;
          });
    },

  
  },
};
