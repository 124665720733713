import requests from "./pages/requests/store"
import bookings from "./pages/bookings/store"
import hallsServices from "./pages/reception/store"
import hallsOccupancy from "./pages/halls-occupancy/store"
import manageServices from "./pages/services/store"
import manageVisitor from "./pages/visitors/store"
import office from "./pages/office/store"
import legalAwareness from "./pages/legal-awareness/store"
import financialProducts from "./pages/financial-products/store"
import mayors from "./pages/community-services/pages/mayors/store"
import communityCommittees from "./pages/community-services/pages/community-committees/store"
import discoverAncientCity from "./pages/discover-ancient-city/store"
export default {
  namespaced: true,
  modules:{
    requests,
    bookings,
    hallsServices,
    hallsOccupancy,
    manageServices,
    manageVisitor,
    office,
    legalAwareness,
    financialProducts,
    mayors,
    communityCommittees,
    discoverAncientCity
  }
}
